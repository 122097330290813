import React from 'react'

import { CgInstagram } from 'react-icons/cg'
import { Container, Content } from './styles'

import MenuMobile from '../../components/Menu/mobile'
import Footer from '../../components/Footer'

import image4 from '../../assets/imagens/pessoas/pessoas_04.png'
import image5 from '../../assets/imagens/pessoas/pessoas_05.png'
import image6 from '../../assets/imagens/pessoas/pessoas_06.png'
import image12 from '../../assets/imagens/pessoas/pessoas_12.png'
import image27 from '../../assets/imagens/pessoas/pessoas_27.png'
import image28 from '../../assets/imagens/pessoas/pessoas_28.png'
import image15 from '../../assets/imagens/pessoas/pessoas_15.png'
import image31 from '../../assets/imagens/pessoas/pessoas_31.png'
import image36 from '../../assets/imagens/pessoas/pessoas_36.png'
import image38 from '../../assets/imagens/pessoas/pessoas_38.png'
import image39 from '../../assets/imagens/pessoas/pessoas_39.png'
import image41 from '../../assets/imagens/pessoas/pessoas_41.png'

const Pessoas: React.FC = () => {
  return (
    <Container>
      <MenuMobile pageName="pessoas" />
      <Content>
        <h1>pessoas</h1>
        <h1 className="tituloPessoas1">pessoas</h1>
        <h1 className="tituloPessoas2">pessoas</h1>

        <section className="grid center">
          <div className="item">
            <img src={image4} alt="cuidado e informação" />
            <p>Monique Alfradique</p>
            <a
              href="https://www.instagram.com/moniquealfradique/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image36} alt="cuidado e informação" />
            <p>CRIS VIANNA</p>
            <a
              href="https://www.instagram.com/crisvianna/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image5} alt="cuidado e informação" />
            <p>JULIA KONRAD</p>
            <a
              href="https://www.instagram.com/juliakonrad/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image31} alt="cuidado e informação" />
            <p>MANOEL SOARES</p>
            <a
              href="https://www.instagram.com/manoelsoares/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image15} alt="cuidado e informação" />
            <p>TATI WESTON-WEBB</p>
            <a
              href="https://www.instagram.com/tatiwest"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image28} alt="cuidado e informação" />

            <p>HENRIQUE FOGAÇA</p>
            <a
              href="https://www.instagram.com/henrique_fogaca74/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image41} alt="cuidado e informação" />
            <p>Renata Kuerten</p>
            <a
              href="https://www.instagram.com/renatakuerten/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image38} alt="cuidado e informação" />

            <p>JOÃO CÔRTES</p>
            <a
              href="https://www.instagram.com/joao_cortes/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image27} alt="cuidado e informação" />
            <p>MICHELI MACHADO</p>
            <a
              href="https://www.instagram.com/michelimachado/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image39} alt="cuidado e informação" />
            <p>LUCAS MORAES</p>
            <a
              href="https://www.instagram.com/lucasmoraes23/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image6} alt="cuidado e informação" />
            <p>BELLA FALCONI</p>
            <a
              href="https://www.instagram.com/bellafalconi/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
          <div className="item">
            <img src={image12} alt="cuidado e informação" />
            <p>MARCOS CAVALLARIA</p>
            <a
              href="https://www.instagram.com/marcosmellocavallaria/"
              target="_blank"
              rel="noreferrer"
            >
              <CgInstagram size={32} color="#f2ae2e" />
            </a>
          </div>
        </section>
      </Content>

      <Footer />
    </Container>
  )
}

export default Pessoas
